/* video bulleting style in candidate home */
.video-content li a {
    line-height: 33px;
}
.video-content li {
    display: block;
	padding-left: 20px;
}
.video-content li a:before
{
    content: "\f144";
    font-family: FontAwesome;
    font-size: 17px;
    float: left;
    margin-top: 10px;
    margin-left: -24px;
    line-height: 17px;
    color: #666;
}
/* candidate event style */
.card-event {
  /*width: 250px;*/
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  /*text-align: center;*/
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 25px;
}
.card-event.upcoming-event {
 -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Firefox 3.5 - 3.6 */
  box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
/*  border-top: 2px solid #06a606; */
}
/* .card-event.attended-event {
 border-top: 2px solid #bf0606;
} */
.start-event {
 text-align: center;
  padding: 8px;
    background-color: #ecf5f5;
}
.card-event .title-date {
    background-color: #ECF5F5;
    color: white;
    padding: 0px 6px;
    font-size: 40px;
}
.title-date {
  padding: 5px 3px;
}
.card-event.container {
    padding: 10px;
}
.event-data {
    text-align: left;
    padding: 10px 7px 10px 40px;
    border-top: 1px solid #ddd;
    font-size: 13px;
    color: #777;
}
.event-title {
    text-align: left;
    padding: 10px 7px 10px 40px;
    border-top: 1px solid #ddd;
    font-size: 15px;
    color: #777;
}
.card-event .e-title:before
{
    content: "\f129";
    font-family: FontAwesome;
    float: left;
    margin-left: -25px;
	font-size: 16px;
}
.card-event .description:before
{
    content: "\f03a";
    font-family: FontAwesome;
    float: left;
    margin-left: -25px;
}
.card-event .date:before
{
    content: "\f073";
    font-family: FontAwesome;
    float: left;
    margin-left: -25px;
	font-size: 14px;
}
.card-event .location:before
{
    content: "\f041";
    font-family: FontAwesome;
    float: left;
    margin-left: -25px;   
	font-size: 17px;
}
/* event showing date */
.nav-tabs>li {
    margin-bottom: 10px;
}
.upcoming-event-color {
    /* color: #006900 !important; */
	/* box-shadow: inset 0 -2px 0 #006900 !important; */
}
.attended-event-color {
    /* color: #bf0606 !important; */
}
.nav-tabs>li.active>a.upcoming-event-color , .nav-tabs>li.active>a.upcoming-event-color :focus {
box-shadow: inset 0 -2px 0 #06a606 !important;
    color: #06a606;
}
.nav-tabs>li.active>a.attended-event-color , .nav-tabs>li.active>a.attended-event-color :focus {
box-shadow: inset 0 -2px 0 #bf0606 !important;
    color: #bf0606;
}
.tab-content div.date-listing.initial {
    border-top: none;
}
.date-listing.initial {
    margin-top: 0;
}
.primary .secondary {
    width: 31.42%;
    float: left;
}
.tab-content h3 {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 1em;
    text-rendering: optimizeLegibility;
}
.date-listing h3 .year {
    display: block;
    font-size: 18px;
    margin-left: 80px;
    color: #B9D1EC;
}
.date-listing h3 .time {
    display: block;
    font-size: 18px;
    margin-top: -2em;
    color: #A7A7A7;
    float:right;
}
.primary .primary {
    width: 65.71%;
    float: right;
}
.primary {
    float: left;
    width: 74.5%;
    position: relative;
}
.conference-listing ul, .conference-listing ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.conference {
    margin: 0 0 1em 0;
    padding: 0;
    clear: both;
}
.conference .startday {
    position: relative;
    top: 0.1em;
    border: 1px solid #8CB4E0;
    width: 1.8em;
    display: block;
    float: left;
    margin-left: -3em;
    padding: 2px;
    text-align: center;
    font-weight: bold;
    color: #3C80CA;
    background-color: white;
}
.startday {
    position: relative;
    top: 0.2em;
    border: 1px solid #8CB4E0;
    width: 2em;
    display: block;
    float: left;
    padding: 1px;
    text-align: center;
    font-weight: bold;
    color: #3C80CA;
    background-color: white;
    margin-right: 20px;
    font-size: 16px;
	margin-left: 5px;
}
.calendar-date {
    display: block;
    width: 100%;
    height: 4px;
    background-color: #B9D1EC;
}
.conference .calendar-date {
    display: block;
    width: 100%;
    height: 4px;
    background-color: #B9D1EC;
}
.conference h4 {
    font-size: 1em;
    margin: 0 0 0.4em 0;
    line-height: 1.4;
}
.tab-content h4 {
    font-size: 1.25em;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}
.conference h4 a:link, .conference h4 a:visited {
    color: #153359;
}
.conference .location {
    margin-bottom: 0.4em;
    font-size: 0.9em;
    zoom: 1;
}
.conference-listing p {
    margin: 0;
}
.conference .location {
    color: #3C80CA;
}
.conference .location .flag-small {
    margin-right: 5px;
    position: relative;
    top: 1px;
}
.flag-uk.flag-small {
    background-position: -136px -48px;
    display: inline-block;
}
.conference .date {
    font-size: 0.9em;
    color: #8395A6;
}
.content .inner:after, .wrapper:after{
    clear: both;
}
.content .inner:before, .content .inner:after, .wrapper:before, .wrapper:after {
    content: "";
    display: table;
}
.conference .calendar-date .ick {
    background-color: #8CB4E0;
    width: 0.25em;
    height: 0.35em;
    position: absolute;
    left: 0.3em;
    top: -0.3em;
}
.calendar-date .ick {
    background-color: #8CB4E0;
    width: 0.25em;
    height: 0.35em;
    position: absolute;
    left: 0.3em;
    top: -0.3em;
}
.conference .calendar-date .uck {
    left: 1.5em;
}
.calendar-date .uck {
    left: 1.2em;
}
#conference-listing {  
    padding: 12px;
   /* border-top: 5px solid #004B87;*/
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.day-label h3 {
    font-size: 18px;
    margin: 10px 0px;
	font-weight: bold;
}       
/*feedback assesment */
.bd-callout {
    padding: 1.25rem;
    margin-top: 1.5em;
    margin-bottom: 1.25rem;
    border: 1px solid #eee;
   /* border-left-width: .25rem;*/
    border-radius: .25rem;
    background-color: #ECF5F5;
}
.bd-callout+.bd-callout {
    margin-top: 1em;
}
.event-on {
  color: #666;
 }
h4.event-on {
    font-size:20px;
}
 .feedback-event{
   background-color: #FFFFFF;
}
.tab-content {
 min-height:615px;
}
.cursor-pointer {
cursor:pointer;
}
.min-height-620 {
min-height:620px;
}
.dropdown-active {
    border-bottom: 1px solid #005B88;
}
.contact-content {
    min-height: 715px;
}
.img-responsive {
    width: 100%;
}
.sign-in {
   box-shadow: none;
}
.about-info {
    min-height: 877px;
}
.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #ECF5F5;
}
@media (max-width: 768px) {
.navbar-custom ul.nav ul.dropdown-menu {
    width: 95%;
    margin-left: 4%;
}
}
