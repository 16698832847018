/* *{
	padding:0;
	margin:0;
} */

body {
    width: 100%;
    height: 100%;
    font-family: 'fs_trumanlight',verdana,sans-serif;
	font-weight: 300;
    color: #666;
    background-color: #fff;
	font-size: 14px;
    line-height: 1.6em;
	font-weight: 400;
	overflow-x: hidden;
}

html {
    width: 100%;
    height: 100%;
}

h1 {
	font-size: 38px;
}
h2 {
	font-size: 36px;
}
h3 {
	font-size: 32px;
}
h4 {
	font-size: 28px;
}
h5 {
	font-size: 24px;
}
h6 {
	font-size: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 30px;
    /* font-family: 'Roboto', sans-serif; */
    font-weight: 400;
	color: #111;
}

h1.h-xlight,h2.h-xlight,h3.h-xlight ,h4.h-xlight ,h5.h-xlight ,h6.h-xlight  {
	font-weight: 200;
}

h1.h-light,h2.h-light,h3.h-light ,h4.h-light ,h5.h-light ,h6.h-light  {
	font-weight: 300;
}

h1.h-bold,h2.h-bold,h3.h-bold ,h4.h-bold ,h5.h-bold ,h6.h-bold  {
	font-weight: 600;
}

h1.font-light,h2.font-light,h3.font-light,h4.font-light,h5.font-light,h6.font-light {
	color: #fff;
}

.big-heading {
	font-size: 48px;
	font-weight: 700;
}

p {
    margin: 0 0 20px;
}

p.lead {
	font-weight: 600;
}

.highlight-light {
	background-color: rgba(255,255,255,.5);
	padding: 20px;
	display: inline-block;
	color: #111;
}

.highlight-dark {
	background-color: rgba(0,0,0,.5);
	padding: 20px;
	display: inline-block;
	color: #fff;
}

.highlight-color {
	padding: 20px;
	display: inline-block;
}

a {
    color: #666;
    font-weight: bold;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 24px;
	line-height: 1.5em;
  font-weight: 300;
  color: #999;
}

blockquote.bg-light {
	background-color: rgba(255,255,255,.8);
	padding: 20px;
	color: #222;
}

blockquote.bg-dark {
	background-color: rgba(0,0,0,.8);
	padding: 20px;
}

blockquote strong {
  font-weight: 700;
}


.light {
    font-weight: 400;
}

/* --- logo --- */
.site-logo {
	margin-top: 10px;
}
.site-logo a.brand {
	color: #111;
	text-transform: uppercase;
	font-size: 32px;
	font-family: 'Roboto', sans-serif;
	font-weight: 900;
	margin-top: 20px;
    outline: 0;
}

/* ------- Navigation ------------ */
#navigation {
	background: #fff;
	z-index: 9999;
	width:100%;
    box-sizing:border-box;
	border-bottom: 1px solid #eee;
	box-shadow:0px 0px 20px rgba(0, 0, 0, 0.1);
}

.navbar {
    margin-bottom: 0;
}

.navbar-brand {
    font-weight: 700;
}


.navbar-brand:focus {
    outline: 0;
}

.nav.navbar-nav {
    background-color: rgba(255,255,255,.6);
	padding: 19px 0px;
}

.navbar-custom.top-nav-collapse .nav.navbar-nav {
    background-color: rgba(0,0,0,0);
}


.navbar-custom ul.nav li a {
	font-size: 14px;
	letter-spacing: 1px;
    color: #444;
	text-transform: uppercase;
	font-weight: 700;
}

.navbar-custom.top-nav-collapse ul.nav li a {
	    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
	color: #fff;
}

.navbar-custom ul.nav ul.dropdown-menu {
	  border-radius: 0;	
	margin-top: 3px;
	border-top: none;
	    min-width: 140px;
}

.navbar-custom ul.nav li a:hover ul.dropdown-menu {
	    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}


.navbar-custom ul.nav ul.dropdown-menu li {
	border-bottom: 1px solid #f5f5f5;

}
.dropdown-menu li :hover {border-bottom: 1px solid #005B88;}
.navbar-custom ul.nav ul.dropdown-menu li:last-child{
	border-bottom: none;
}

.navbar-custom ul.nav ul.dropdown-menu li a {
	padding: 7px 10px;
    text-transform: capitalize;
    font-size: 13px;
}
.navbar-custom ul.nav ul.dropdown-menu li a:hover {
  /*   border-bottom: 1px solid #005B88; */
}
.navbar-custom ul.nav ul.dropdown-menu li a:hover {
	background: #fefefe;
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {background:none;color: #666;}

.navbar-custom.top-nav-collapse ul.nav ul.dropdown-menu li a {
	color: #666;
}

.navbar-custom .nav li a {
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
}

.navbar-custom .nav li:hover {
    outline: 0;
    background-color: rgba(255,255,255,.2);
	border-bottom: 2px solid #005B88;
}
.navbar-custom .nav li {
	border-bottom: 1px solid #E1E1E1;
    margin-left: 3px;
    margin-right: 3px;
}
.navbar-custom .nav li.active {
    outline: 0;
    background-color: rgba(255,255,255,.2);
	border-bottom: 2px solid #005B88;
}
.nav>li>a:hover, .nav>li>a:focus {
    text-decoration: none;
    background-color: rgba(255,255,255,.2);
}

.navbar-toggle {
    padding: 4px 6px;
    font-size: 14px;
    color: #fff;
}

.navbar-toggle:focus,
.navbar-toggle:active {
    outline: 0;
}


/* misc */
hr {
  margin-top: 10px;
}

hr.bold {
  border-top: 3px solid #eee;
}

.header-underline {
	display: block;
	width: 12%;
	height: 2px;
	margin: 0 auto;
	margin-bottom: 10px;
}
.section-heading .header-underline  {
   margin: 0;
   margin-left: 45px;
}
.divider-short {
	display: block;
	width: 60px;
	height: 5px;
	margin: 0 auto;
	margin-bottom: 10px;
}

/* margins */

.marginbot-20{margin-bottom:20px !important;}


/* ===========================
--- General sections
============================ */

.home-section {
    padding-top: 115px;
    padding-bottom: 70px;
	display:block;
    position:relative;
    z-index:120;
    border-bottom: 2px solid #004B87;
    padding-right: 10px;
    padding-left: 10px;
}
.inner-section {
    padding-top: 110px;
    padding-bottom: 110px;
}


.home-section.nopadd-bot {
    padding-bottom: 0;
}


.section-heading h2 {
	font-size: 20px;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-bottom: 10px;
    display: inline-block;
}

.section-heading p {
	font-weight: 300;
	font-size: 20px;
	line-height: 1.6em;
}

.color-dark .section-heading h2 {
	color: #111;
}
.color-dark .section-heading p {
	color: #444;
}

.color-dark .divider-short {
	background: #666;
}
.color-dark .header-underline {
	background: #004b87;
}
.header-underline {
	background: #fff;
}
.color-light .divider-short {
	background: #666;
}
.color-light .header-underline {
	background: #666;
}


.color-light .section-heading h2 {
	color: #fff;
}
.color-light .section-heading p {
	color: #fefefe;
}

.color-light blockquote,.color-light p {
	color: #fefefe;
}

/* --- section bg var --- */

.bg-white {
	background: #fff;
}

.bg-gray {
	background: #f9f9f9;
}


.bg-dark {
	background: #404040;
}

/* --- section color var --- */

.text-light {
	color: #fff;
}



/* ===========================
--- Intro
============================ */
.intro-section { 
    /* background: url(../img/bps-bg9.png) repeat ; 
    -webkit-background-size: cover; 
    -moz-background-size: cover; 
    -o-background-size: cover; 
    background-size: cover; */ 
    padding: 4% 0 4% 0; height: 100%;
    background-attachment: fixed;
}
.intro-section h1 { 
    font-size: 40px;
    letter-spacing: 12px; 
    margin: 0px 0 5%; 
    font-weight: 900; 
    color: #fff;
}
.intro-section h1 span { 
    color: #a1a9b0;
    display: inline-block; 
}
.intro-section p { 
    font-size: 24px; 
    font-weight: 400; 
    color: #555; 
    margin-bottom: 15%;
}
.learn-more-btn { 
    background: rgba(61, 201, 179, 1); 
    padding: 20px 40px; 
    border-radius: 5px; 
    -moz-border-radius: 5px; 
    -webkit-border-radius: 5px; 
    -o-border-radius: 5px; 
    color: #fff; 
    text-transform: uppercase; 
    letter-spacing: 1px; 
    display: inline-block; 
    -webkit-box-shadow: 0px 3px 0px 0px #309383; 
    -moz-box-shadow: 0px 3px 0px 0px #309383; 
    box-shadow: 0px 3px 0px 0px #309383; 
    margin: 60px 0 0; font-weight: bold; 
}
.learn-more-btn:hover, .learn-more-btn:focus { 
    background: rgba(61, 201, 179, 0.6); 
    color: #fff; 
}
.intro {
	width:100%;
	position:relative;
	padding:0;
}
.intro-inner {
	width:100%;
	position:relative;
	padding:120px 0 20px;
}

.brand-heading {
    font-size: 40px;
}

.intro-text {
    font-size: 18px;
}


/* Carousel Styles */

.carousel-inner img {
    width: 100%;
    max-height: 650px;
}

.carousel-control {
    width: 0;
}

.carousel-control.left,
.carousel-control.right {
	opacity: 1;
	filter: alpha(opacity=100);
	background-image: none;
	background-repeat: no-repeat;
	text-shadow: none;
}

.carousel-control.left span {
	padding: 0;
}

.carousel-control.right span {
	padding: 0;
}

.carousel-control .glyphicon-chevron-left, 
.carousel-control .glyphicon-chevron-right, 
.carousel-control .icon-prev, 
.carousel-control .icon-next {
	position: absolute;
	top: 45%;
	z-index: 5;
	display: inline-block;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
	left: 30px;
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
	right: 30px;
}



.carousel-control.left span:hover,
.carousel-control.right span:hover {
	opacity: .7;
	filter: alpha(opacity=70);
}

.carousel-inner > .item {
  margin-left:0;
  margin-top:0;
  margin-bottom:0;
  padding-left: 0;
  width: 100%;
  height: auto;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left:0;
  padding-left: 0;
}


/* Carousel Header Styles */
.header-text {
    position: absolute;
    top: 20%;
    left: 1.8%;
    right: auto;
    width: 96.66666666666666%;
    color: #fff;
}

.header-text h2 {
    font-size: 40px;
}

.header-text h2 span {
	padding: 10px;
	color: #fff;
	text-transform: uppercase;
}

.header-text h3 span {
	color: #fff;
}

.btn-min-block {
    min-width: 170px;
    line-height: 26px;
}

.btn-theme {
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    margin-right: 15px;
}

.btn-theme:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
}

/* ===========================
--- About
============================ */




/* ===========================
--- Services
============================ */
.service-box {
	 background: #007FA3; 
	/*background: url(../icons/cube1.png) no-repeat;*/
	/* border-bottom: 2px solid #f7f7f7; */
	/*padding: 71px 20px;*/
    padding: 40px 20px;
	background-size: 100% 100%;
    color: #ffffff;
   /* margin: 0 auto;
    width: 75%;*/
}

.service-box:hover {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
	margin-top: -20px;
}

.service-icon {
	margin-bottom: 20px;
}	

.service-desc h5 {
	text-transform: uppercase;
	font-weight: 700;
	color: #fff;
	font-size: 14px;
	margin-bottom: 10px;
}
.service-desc .header-underline {
	/*margin-bottom: 30px;*/
    margin-bottom: 10px;
}
.pd-leftright {
   padding: 0 7%;
}
/* ===========================
--- Works
============================ */

#owl-works {

}

.item {
    margin: 10px;
}

.item img {
    display: block;
    width: 100%;
    height: auto;
}	

/* ===========================
--- Contact
============================ */


.form-group {
  margin-bottom: 20px;
  position: relative;
}

form#contact-form .form-group label {
	text-align: left !important;
	display: block;
	text-transform: uppercase;
	font-size: 16px;
}
form#contact-form input,form#contact-form select,form#contact-form textarea {
	font-size: 14px;
	line-height: 1.6em;
	border: 1px solid #eee;
  -webkit-box-shadow: none;
          box-shadow:  none;
	border-radius: 2px;
}

.btn-lg,
.btn-group-lg > .btn {
  border-radius: 2px;
}

form#contact-form input:focus,form#contact-form select:focus,form#contact-form textarea:focus {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
          box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}
.input-group-addon {
  background-color: #fefefe;
  border: 1px solid #eee;
}

.widget-contact {
	text-align: left;
}



/* ===========================
--- Bottom
============================ */

.boxcol-25 {
	width: 100%;
}

.box-social {
	list-style: none;
}

.box-social li {
	width: 25%;
	float: left;
}


.box-social .social-facebook a { 
	display: block;
	background: #3873ae;
}


/* ==== Sidebar ==== */
.sidebar .widget h5 {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 20px;
	text-transform: uppercase;
}
.sidebar .widget {
	margin-bottom: 50px;
}

.widget ul {
	list-style: none;
	margin-left:0;
	padding-left:0;
}
.sidebar .widget ul li {
	margin-left:0;
	padding-left:0;
	margin-bottom: 10px;
}
.sidebar .widget ul li a {
	color: #666;
}


.widget.widget_categories ul li:before {
	display: inline-block;
	font-family: 'Pe-icon-7-stroke';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: "\e684";
	margin-right: 10px;
}


.widget.widget_recent_post ul li figure {
	float: left;
	margin: 0 20px 0 0;
}

.widget.widget_recent_post ul li {
	margin-bottom: 25px;
}

.widget.widget_recent_post ul li:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.widget.widget_recent_post ul li h6 {
	margin-bottom: 10px;
}
.widget.widget_recent_post ul li span {
	font-size: 14px;
}

.widget .tagcloud a {
	padding: 4px 10px;
	color: #fff;
	display:inline-block;
	margin: 0 10px 10px 0;
	font-size: 14px;
	border-radius: 2px;
}

.widget .tagcloud a:hover {
	color: #fff;
	background: #333;
}

/* --- widget --- */

form.search-form input {
	font-size: 18px;
	line-height: 1.6em;
	border: 1px solid #eee;
  -webkit-box-shadow: none;
          box-shadow:  none;
	border-radius: 2px;
}

form.search-form input:focus {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
          box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

.display-none{
    display: none;
}

/* ===========================
--- Footer
============================ */

footer {
    background-color: #004851;
}

footer {
	text-align: center;
	padding: 50px 0 15px ;
	
}

footer p {
	color: #f8f8f8;
	font-size: 12px;
}

footer a.totop {
	color: #fff;
	margin-bottom: 30px;
}
footer a.totop:focus {
	outline: 0;
}
.imgHexagon {
  background-image: url("../icons/FacebookIcon.png");
  position: relative;
  width: 60px; 
  height: 34.64px;
  margin: 17.32px 0;
  background-size: auto 69.2820px;
  background-position: center;
}

.imgHexTop,
.imgHexBottom {
  position: absolute;
  z-index: 1;
  width: 42.43px;
  height: 42.43px;
  overflow: hidden;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  -ms-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
  background: inherit;
  left: 8.79px;
}

/*counter transform the bg image on the caps*/
.imgHexTop:after,
.imgHexBottom:after {
  content: "";
  position: absolute;
  width: 60.0000px;
  height: 34.64101615137755px;
  -webkit-transform:  rotate(45deg) scaleY(1.7321) translateY(-17.3205px);
  -ms-transform:      rotate(45deg) scaleY(1.7321) translateY(-17.3205px);
  transform:          rotate(45deg) scaleY(1.7321) translateY(-17.3205px);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  background: inherit;
}

.imgHexTop {
  top: -21.2132px;
}
.imgHexTop:after {
  background-position: center top;
}
.imgHexBottom {
 bottom: -21.2132px;
}
.imgHexBottom:after {
  background-position: center bottom;
}
.imgHexagon:after {
  content: "";
  position: absolute;
  top: 0.0000px;
  left: 0;
  width: 60.0000px;
  height: 34.6410px;
  z-index: 2;
  background: inherit;
}
.facebook{
    background-image: url("../icons/FacebookIcon.png");
    background-size: cover;
}
.linkedIn{
    background-image: url("../icons/LinkedIn%20Icon.png");
    background-size: cover;
}
.twitter{
    background-image: url("../icons/Twitter%20Icon.png");
    background-size: cover;
}
.youTube{
    background-image: url("../icons/YouTube%20Icon.png");
    background-size: cover;
}
.rssImg{
    background-image: url("../icons/RSS%20Feed%20Icon.png");
    background-size: cover;
}
.footer-links a {
}
a{
    color:#1F96F5;
}
.footerLogo img{
    width: 50mm;
}

/* menu */
ul.footer-menu {
	list-style: none;
}

ul.footer-menu li {
	float: left;
	margin: 0 0 0 10px;

}
ul.footer-menu li a {
	color: #fff;
	font-size: 14px;
	padding-left: 9px;
	line-height: 1.1em;
}
ul.footer-menu li:first-child a {
	border-left: none;
	padding-left: 0;
}

.social-widget {
	margin-top: 20px;
	margin-bottom: 30px;
}
.social-widget ul li a:hover {
	color: #fff;
}
footer .social-widget ul.team-social li a {
	margin: 0 5px;
}

/* tab drops */
.nav-tabs,
.nav-pills {
  position: relative;
}

/* BPS home page style =====================================================*/

.card-title{
    font-size: 14px;
    font-weight: bold;
    color: rgb(0,178,169);
}
.text-title{
    font-size: 16px;
    position: relative;
    font-size: 18px;
    position: relative;
    font-weight: bold;
    padding-left: 10px;
}
.text-title:after {
    left: 10px;
    width: 50px;
    height: 2px;
    content: " ";
    bottom: -10px;
    background: #00B2A9;
    position: absolute;
}
.custom-btn{
    border-radius: 2px;
    outline: none;
    border: none !important;
    background: rgba(3, 3, 3, 0.83);
}
.login-div{
    position: absolute;
    background-color: #fff;
    background: #fcfcfc;
    box-shadow: 0 1px 3px #ddd;
    width: 300px;
    right: 0;
	z-index:999;
}

#mobileMenuToggle {
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}
.mobile-menu li{
  display: inline-block;
}
.loginBox-footer{
    margin-top: 15px;
    margin-left: 10px;
}
.memberIcon{
    background-image: url("../icons/memberIcon.png");
    background-size: cover;
    width: 30px;
}
.passwordIcon{
    background-image: url("../icons/Eye.png");
    background-size: cover;
    width: 30px; 
}
.forgotPass{
    margin-top: 8px;
}

/*who's involved*/
.team-memberBox{
    background-color: #F1F1F1;
    padding: 5px;
    border: 1px solid #ddd;
    -moz-border-radius: 2px;
    border-radius: 2px;
    min-height: 260px;
}
.member-img{
    background-image: url("../images/profile_user.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100px;
    background-position: center;
}

.teamMember-name{
    font-size: 16px;
    font-weight: bold;
}
.itemAuthorContent p{
    font-size: 14px;
    line-height: 22px;
    margin: 10px 15px;
    text-align: left;
    color: #777;
    padding-left: 15px;
    border-left: 8px solid rgba(63,148,148, 0.1);
}
.mobileMenu {
    position: absolute;
    min-width: 200px;
    z-index: 999;
    left: -310px;
    top: 215px;
}
.hamburger-div {
/*    position: absolute;*/
    z-index: 999;
    cursor: pointer;
}
.hamburger-icon {
    font-size: 20px;
}
.PsaCycle-img{
    width: 500px;
    height: 400px;
}
.home-img{
  width: 280px;
  height: auto;
}
.home-content{
    margin-left: 140px;
}
#nav{
    transition: all 0.3s ease 0s;
}
/*
#nav.affix {
    position: fixed;
    top: 0;
    width: 100%
}
*/
.courseImg {
    background-image: url(../images/students-study.jpg);
    background-size: cover;
    height: 215px;
}
.courseImg1{
    background-image: url(../images/justification.jpg);
    background-size: cover;
    height: 215px; 
}
.left-navigation1 p {
    margin-bottom: 0px;
    font-size: 14px;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: #0b94d6;
    height: 40px;
    padding-top: 9px;
    cursor: pointer;
}
/*Assesment structure page css*/

.assesmentTable{
   border: 1px solid #ddd;  
}

#wrap {
    min-width:600px;    
}
.mobile-login{
    font-size: 16px;
    color: white;
    margin-top: -5px;
}
.mobile-login li a{
    color: white;
    padding: 0px 15px;
}
.logged-in-user a{
   font-size: 16px;
   color: white; 
}

/*Resources page*/

.prescribReq{
    border: 1px solid #ddd;
    margin-top: 10px;
    padding: 0px 10px;
    background-color: #F5FDE9;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
}
.casePresentaion{
    border: 1px solid #ddd;
    padding: 0px 10px;
    background-color: #F7FEFF;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
}
.questionPanel {
    background-color: #F5FDE9;
    border: 1px solid #ddd;
    margin-top: 10px;
    padding: 0px 10px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
}
.color-black{
    color: black;
}
.caseStudy-Img{
    margin-top: 30px;
}
.qsn-type{
    padding: 1px 8px;
    display: inline-block;
    background-color: #009F4D;
    color: white;
}
.Qsn-marks{
    padding: 5px 8px;
    display: inline-block;
    color: #040404;
    border-radius: 3px;
    float: right;
    font-weight: bold;
}
.sideImg{
    padding-top: 12px;
    clear: both;
}
.leftIcon{
   font-size: 100px; 
}
.rightIcon{
   font-size: 100px; 
}
.bnfImg{
    margin-right:35px;
    float:right;
}
.textContentDiv{
    position: relative;
    display: inline-block;
}
.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
    color: #fff;
    background-color: #7597B1;
}
.custom-panel-bg {
    background: none;
    box-shadow: none;
    margin-top: 0px !important;
    margin-bottom: 8px !important;
}
.custom-panel-heading-bg {
   background: none !important;
   padding: 0;
}
.custom-panel-heading-bg > .panel-title > .bg-custom {
	background-color: #f5f5f5;
	padding: 16px 15px;
    display: block;
    font-size: 15px;
}
.custom-panel-heading-bg > .panel-title > .bg-custom.collapsed {
	background: none;
	color: #1F96F5;
	/*border-bottom: 1px solid #f6f6f6;*/
}
.custom-panel-body {
    padding: 15px 15px;
    border: 1px solid rgb(237, 237, 237);
}
/* end for resources */


.qsnImgDiv{
    margin-top: 33px;
}
.questionSection{
    clear: both;
    padding-top: 25px;
}
.bnfInfo:hover,.abrvInfo:hover,.bnfInfo:active,.abrvInfo:active,.bnfInfo:focus,.abrvInfo:focus{
    color: white;
}
.answerDiv{
    border: 1px solid #ddd;
    margin-top: 10px;
    padding: 10px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
	background-color: #fff;
}
.width80{
    width: 80px;
}
.table_chart-hpc2{
    border-spacing: 0px;
    border-collapse: collapse;
    border: 1px solid black;
    background: #ffffff;
    float: left;
    margin-left: 22px;
    margin-right: 0px;
    clear: both;
    width: 95%;
}
.border-1px{
   border:1px solid #ddd; 
}
.border-top-Right{
  border-right:1px solid #ddd;
  border-top:1px solid #ddd;  
}
.width80{
    width: 80px;
}
.width115{
    width: 115px;
}
.width200{
    width: 200px;
}
.width70{
    width: 70%;
}
.width60{
    width: 60%;
}
.width15{
    width: 15%;
}
/*------ section question bg color-----*/
.prescribing-item {
    background-color: #009F4D !important;
    color: #fff !important;
}
.prescription-review-item {
    background-color: #1556c7 !important;
    color: #fff !important;
}
.planning-management-item {
    background-color: #A02C2C !important;
    color: #fff !important;
}
.communication-Info{
    background-color: #2DC0D8;
	color: #fff !important;
}
.adverse-Drug{
    background-color: #e4de18 !important;
	color: #000 !important;
}
.drug-monitoring-item{
   background-color: #DE890C;
   color: #fff !important;
}
.data-interp-item {
    background-color: #9e9797 !important;
    color: #ffffff !important;
}
.calculation-skills-item {
    background-color: #e9e9e9 !important;
     color: #000 !important; 
}
/*------ end section question bg color----*/
/* end BPS home page style =========================================================*/


.panel-default {
 opacity: 0.9;
 margin-top:15px;
}
.form-group.last {
 margin-bottom:0px;
}


/* Media queries */

@media(min-width:767px) {

    .navbar {
        padding: 0px 0 7px;
        border-bottom: 0;
        letter-spacing: 1px;
        background: 0 0;
        -webkit-transition: background .5s ease-in-out,padding .5s ease-in-out;
        -moz-transition: background .5s ease-in-out,padding .5s ease-in-out;
        transition: background .5s ease-in-out,padding .5s ease-in-out;
    }

    .top-nav-collapse {
        padding: 0;
        background-color: #000;
    }

    .navbar-custom.top-nav-collapse {
        border-bottom: 1px solid rgba(255,255,255,.3);
    }
	
    .intro {
        height: 100%;
        padding: 0;
    }

    .brand-heading {
        font-size: 100px;
    }
	

    .intro-text {
        font-size: 25px;
    }


}
@media (max-width:768px) {
	.site-logo{
		margin: 0 0 0 30px;
		float:none;
	}
	.navbar-custom ul.nav li a {
		color: #111;
	}
	.navbar-custom ul.nav ul.dropdown-menu li {
		border-bottom: 1px solid #aaa;

	}
	.navbar-header {
		margin-top:-40px;
		padding:0;
	}
	.navbar-header button {
		background: #004B87;
	}
	
	.navbar-header button.navbar-toggle {
		padding: 5px 15px;
		font-size: 14px;
		color: #fff;
	}
	
	.navbar-custom .nav {
/*		background: #444;*/
	}
	
	.service-box {
		margin-bottom: 10px;
	}
	
	.team-wrap {
		margin-bottom: 30px;
	}
	
	form#contact-form {
		margin-bottom: 50px;
	}
	
	.xs-marginbot-20 {
		margin-bottom: 20px;
	}

}

@media (max-width:480px) {

	.navbar-custom .nav.navbar-nav {
    background-color: rgba(255,255,255,.4);
	}
	
	.navbar.navbar-custom.navbar-fixed-top {
		margin-bottom: 30px;
	}
}

/*media query*/
@media (min-width: 1200px){
    .container{
        width:  1180px;
    }
}
@media (max-width: 1200px){
    .service-box{
        padding: 50px 20px;
    }
    .hexMenu-text{
        margin-top: -10px;
    }
}
@media (max-width: 992px){
    .img-size{
        width: 188.976378px;
    }
    .intro-section p{
        font-size: 18px;
    }
    .pd0-mobile{
        padding: 0px;
    }
    .service-box {
    padding: 30px 20px;
 }
     .site-logo img{
        width: 113.385827px;
        margin-top: 10px;
    }
    .navbarLogo-mobile{
        float: left;
    }
    .homeSection-pd{
        padding-left: 15px;
        padding-right: 15px;
    }
	.pd-leftright {
	 padding:0;
	}
}
@media (max-width: 500px){
    .service-box {
        padding: 30px 12px;
        background-size: 110% 75%;
    }
    .service-icon {
        margin-bottom: 3px;
        margin-top: -5px;
        margin-left: 10px;
    }
    .service-icon .fa{
        font-size: 15px;
    }
    .service-desc h5{
        text-transform: capitalize;
        font-size: 10px;
        margin-bottom: 5px;
        margin-top: 0px;
    }
    .mr-mobile{
        margin-bottom: 10px;
    }
    .section-heading h2{
        font-size: 16px;
    }
}
@media (min-width: 501px) and (max-width: 768px){
    .service-box {
        padding: 30px 20px;
        background-size: 100% 85%;
    }
    .service-desc h5{
        font-size: 12px;
    }
    .email-loginDiv{
        display: inline-block;
    }
    .email-field{
        width: auto;
        display: inline-block;
    }
}
@media (min-width: 768px) and (max-width: 992px){
    .navbar-custom ul.nav li a{
        font-size: 12px;
    }
}
@media (min-width: 768px) and (max-width: 1200px){
    .tabdropWidth{
        width: 90%;
    }
}

.pd-0 {
padding:0px;
}
.pdT-10{
    padding-top: 10px;
}
.mrT-5{
    margin-top: 5px;
}
.mrT-10{
    margin-top: 10px;
}
.mrB-10 {
    margin-bottom: 10px
}
.mrB-20 {
    margin-bottom: 20px !important;
}

.assesmentTestLink span{
    font-weight: bold;
    font-size: 14px;
}
/* BPS Static page style =================================================*/
.nav>li>a {
    padding: 10px 5px;
	outline: none;
}
.nav>li, .nav>li>a {
margin-right: 2px;
}
.nav>li:last-child, .nav>li>a:last-child {
margin-right: 0px;
}
.tab-content {
    padding: 15px 0px;
	min-height: 620px;
}
.faq-content {
    padding-bottom:20px;
}
.pd-rightleft {
  padding: 0px 15px;
}
.team-memberBox {
 margin-bottom: 25px;
 padding: 15px 10px;
}
div#pane1 {
min-height: 460px;
}
.activation-textbox {
  margin-top: 5%;
}
.btn-primary {
 /* background-color:#007FA3;
  border-color:#004B87;*/
  border-radius: 0px;
}
.log-in-menu {
    font-weight: 500 !important;
	margin-left: 5px !important;
}
.footer-contact {
    margin-bottom: 37px;
    text-align: center;
    color: #fff;
}
.footer-link {
    border-bottom: 1px solid #364B4E;
    padding-bottom: 20px;
}
.footer-copy {
margin-top: 20px;
    font-size: 11px;
}
.navbar-custom .nav li:last-child:hover {
  /*   border: none; */
}

.section-background-image {
 background-image: url("../images/bps-bg10.png");
    background-repeat: no-repeat;
    /* background-position: center */;
    background-size: 100%;
	background-attachment: fixed;
}
.section-background-image-home {
 background-image: url("../images/bps-bg10.png");
    background-repeat: no-repeat;
    /* background-position: center */;
    background-size: 100%;
	background-attachment: fixed;
}
.sectionTitle-logo{
    /*background-image: url(../icons/Cube%201.png);   
    background-position: 60%;
    padding: 12px 12px;
    color: white;
    font-size: 18px;
    background-size: cover;
    cursor: default;*/
    padding: 8px 8px 8px 0px;
}
.title-icon-home {
    height: 25px;
    margin-top: -10px;
}
.title-icon-envolope {
    height: 25px;
    margin-top: -10px;
}
.title-icon-group {
     height: 25px;
    margin-top: -10px;
}
.title-icon-binder {
    height: 25px;
    margin-top: -10px;
}
.sectionTitle-logo i{
    font-size: 20px;
}
.sectionTitle-logo:hover{
    color: white;
}

.textContentDiv {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}
.content-title{
    font-size: 15px; 
    /*margin-left: -8px;*/
}
.tabdrop{
    cursor: pointer;
}
.img-size{
    width: 265px;
}
.site-logo img{
        width: 189px;
}
.whos-inv-img {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
}
.hr-whos-inv {
    margin-bottom: 10px;
    border-top: 2px solid #004B87;
    width: 25%;
}
.footer-section {
    /*background-color: #111;*/
}
.social-icons-image {
    width: 45px;
}
.social-icons {
   text-align:right;
}
.footerLogo {
   text-align:left;
}
.whos-inv-box {
    padding-left: 30px;
    padding-right: 30px;
}
.teamMember-name{
    font-size: 13px;
    font-weight: bold;
}
.teamMember-degree{
    font-size: 12px;
}
.team-memberBox {
    background-color: #EDF9FB;
	min-height: 315px;
}
.card-white {
    position: relative;
    overflow: hidden;
    cursor: default;
    background: none;
    color: #333 !important;
    padding: 0px;
    /* -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.33); */
    box-shadow: none; 
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 14px; 
    
}
.pager li>a, .pager li>span {
    cursor: pointer;
    border-radius: 0px;
    padding: 5px 10px;
    /* width: 80px; */
}
.test-page-num {
    font-size: 18px;
    padding: 0px 9px 4px 7px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: #007FA3;
	background-color: #fff;
	width: 85px;
    display: inline-block;
}
.bnfImg {
    float: none;
}
.disabled-next {
    text-decoration: none;
    background-color: #eee !important;
	color: #000 !important;
    cursor: no-drop !important;
}
.disabled-prev {
    text-decoration: none;
    background-color: #eee !important;
	color: #000 !important;
    cursor: no-drop !important;
}
.display-none {
    display:none !important;
}
.hr-test-page {
    margin-top: -40px;
}
.contact-content {
    min-height: 710px;
}
.author-list {
margin-bottom:20px;
}
.author-list > li{
   line-height:30px;
}
/*==== landing page custom style===*/
.sign-in {
box-shadow:none;
}
.top-icons {
outline:0;
}
.top-icons:active, .top-icons:focus{
        outline: none; /* Works in Firefox, Chrome, IE8 and above */ 
    }
.background-none {
    background:none;
}
.landing-pg-icon {
    height: 55px;
}
.landing-pg-title {
    color:#666 !important;
}
.underline-image {
   background: #666;
   background: url(../icons/underline.png);
   background-repeat: no-repeat;
   background-size: 100%;
}
/*========Error message======*/
.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
    -moz-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
   -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
    margin-bottom: 0px;
    margin-top: 15px;
}
.msg > ul > li {
    list-style: none;
    margin-left: -32px;
}
.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
    -moz-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
   -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
    margin-bottom: 0px;
    margin-top: 15px;
}
/*end message*/

/*========= Login style ============*/

/*
.bps-login-div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    margin-top: -275px;
}
*/

.bps-login-div .panel-default>.panel-heading {
    background-color: #004B87;
    border-color: #ddd;
    font-size: 20px;
	color: #fff;
}
.login-button {
    background-color: #004B87;
}
.vertical-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.login-logo {
    width: 70mm;
}
.popover {
   outline: none;
   color:#000;
   min-width: 350px;
}
.password-policy {
    position: absolute;
    top: 36px;
    right: -8px;
    font-size: 17px;
    color: #1a5d93;
    width: 30px;
    text-align: center;
    height: 27px;
    cursor: pointer;
}
.login-color {
 color: #1F96F5 !important;
}
/*===== application platform page =======*/
.application-logo {
    width: 50mm;
}
.application-div {
   /* background-color: #f7f7f7;*/
    text-align: center;
    padding: 10px;
    /*box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);*/
    /*width: 25%;*/
    /*margin: 0 auto;*/
    /*opacity: 0.6;*/
    -webkit-box-shadow: 1px 1px 4px rgba(0,0,0,.4);
    -ms-box-shadow: 1px 1px 4px rgba(0,0,0,.4);
    -moz-box-shadow: 1px 1px 4px rgba(0,0,0,.4);
    -o-box-shadow: 1px 1px 4px rgba(0,0,0,.4);
    box-shadow: 1px 1px 4px rgba(0,0,0,.4);
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    padding: 15px 20px;
}
hr.app-hr{
    margin-bottom: 10px;
    border-top: 1px solid #FFF;
}
.login-name {
    color: #555;
    font-size: 20px;
    font-weight: 600;
    margin-top: 40px;
    line-height: 30px;
}
.candidate-login-name {
    color: #555 !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-top: 10% !important;
    line-height: 25px;
    margin-bottom: 20% !important;
}
.login-p {
    color: #555;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 55px;
    line-height: 30px;
}
.app-descibtion {
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-bottom: 10px;
}

/* Float Shadow */
.hvr-float-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-float-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 4%;
  height: 15px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
.hvr-float-shadow:hover, .hvr-float-shadow:focus, .hvr-float-shadow:active {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  /* move the element up by 10px */
}
.hvr-float-shadow:hover:before, .hvr-float-shadow:focus:before, .hvr-float-shadow:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}
.hvr-float-shadow {
    width: 285px;
}
.arrow_box {
    position: relative;
    background: #00B2A9;
	/*border: 4px solid #c2e1f5;*/
}
.e-learning {
    background: #009F4D; 
}
.abstract-sys {
    background: #007FA3;
}
.arrow_box:after, .arrow_box:before {
	bottom: 100%;
	left: 9%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
.arrow_box:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #00B2A9;
    border-width: 10px;
    margin-left: 4px;
    /* left: 48%; */
}
.arrow_box:before {
	border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #DEDEDE;
    border-width: 14px;
    margin-left: 0px;
}
.arrow_box.e-learning:after {
    border-bottom-color: #009F4D;
}
.arrow_box.abstract-sys:after {
    border-bottom-color: #007FA3;
}
.ques-table1 > tbody > tr > td > input.required-field {
width:100%
}
/* media query for question table*/
@media (max-width: 992px){ 
.ques-table1 {
  font-size:12px;
}
}
@media (max-width:768px) {
.vertical-center {
    position: inherit;
    top: 0;
    left: 0;
    transform: none;
	padding: 10% 10px;
}
.landing-pg-icon {
    height: 40px;
}
.social-icons {
   text-align:center;
}
.footerLogo {
   text-align:center;
}
.ques-table1 {
  font-size:10px;
}
}
@media (max-width:480px) {
.ques-table1 > thead:first-child > tr:first-child > th {
padding:0px;
}
.ques-table2 {
font-size:10px;
}
.ques-table2 > thead:first-child > tr:first-child > th {
padding:0px;
}
.ques-table2>tbody>tr>td {
padding:5px 0px;
}
}
/*==========================
         Candidate style 
============================*/
.legend {
    font-size: 13px;
   /* padding: 0px 1px; */
    margin-right: 2px;
    letter-spacing: 1px;
    line-height: 23px;
    width: 45px;
    display: inline-block;
    text-align: center;
    margin-bottom: 3px;
    margin-top: 5px;
}
.border-none > tbody > tr > td {
    border:none;
}
/* end BPS Static page style =================================================*/
